import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";
import ICaseStudy from "../../interfaces/ICaseStudy";
import StrapiImage from "../StrapiImage";
import TechItemBadge from "../TechItemBadge";
import { Typo24, Typo29, Typo35 } from "../Typography";

const Wrapper = styled.div`
  margin-top: 41px;

  &:first-child {
    margin-top: 0;
  }

  @media ${media.tablet} {
    display: flex;
    margin-top: 0;
    position: relative;
    width: 50%;
    padding: 20px 0;
  }

  @media ${media.desktop} {
    width: 33.3333%;
  }
`;

const Card = styled.div`
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid rgba(155, 192, 255, 0.5);

  @media ${media.tablet} {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    padding: 30px 20px;
    border: none;
    background: #fff;
    box-shadow: 5px 5px 17px rgb(101 138 200 / 15%);
    border: 1px solid #658ac824;
  }
`;

const Content = styled.div`
  padding: 16px 19px 20px 16px;
  border-bottom: 1px solid rgba(155, 192, 255, 0.5);

  @media ${media.tablet} {
    padding: 0;
    border-bottom: none;
    text-align: center;
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;

  @media ${media.tablet} {
    display: block;
  }
`;

const ImageWrapper = styled.div`
  @media ${media.tablet} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const Image = styled.div`
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #dedede;
  overflow: hidden;

  & img {
    border-radius: 50%;
  }

  @media ${media.tablet} {
    margin-top: auto;
    margin-bottom: auto;
    height: 220px;
    width: 220px;
    border-radius: 6px;
    background-color: transparent;
    overflow: hidden;

    & .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }

    & img {
      border-radius: 6px;
    }
  }
`;

const Titles = styled.div`
  margin-left: 15px;
  flex-grow: 1;

  @media ${media.tablet} {
    margin: 0;
    margin-top: 20px;
  }
`;

const Title = styled.div`
  @media ${media.tablet} {
    letter-spacing: -0.0028em;
  }
`;

const Position = styled.div`
  margin-top: 12px;

  &:hover {
    opacity: 0.8;
  }

  @media ${media.tablet} {
    letter-spacing: -0.002em;
    text-align: center;
  }
`;

const Techs = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  @media ${media.tablet} {
    display: none;
  }
`;

const TechsDesktop = styled.div`
  display: none;

  @media ${media.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 13px;
  }
`;

interface ITechItemCustom {
  color?: string;
}

const TechItemCustom = styled.div<ITechItemCustom>`
  padding: 2px 12px;
  border-radius: 10px;
  background-color: ${({ color }) => (color ? color : "#dedede")};
  margin-right: 5px;
  text-align: center;
  color: ${({ color }) => (color ? "#0B264C" : "#000")};
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0;
`;

const Quote = styled.div`
  margin-top: 15px;
  letter-spacing: -0.01em;

  @media ${media.tablet} {
    margin-top: 13px;
    letter-spacing: 0.0032em;
  }
`;

const ReadMoreWrapper = styled.div`
  @media ${media.tablet} {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: auto;
  }
`;

const ReadMoreButtonWrapper = styled.div`
  @media ${media.tablet} {
    width: 200px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
`;

const Arrow = styled.div`
  margin-left: 5px;
  width: 20px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: transform;
  transition: transform 100ms ease-out;

  @media ${media.tablet} {
    display: none;
  }
`;

const ReadMore = styled.div`
  padding: 16px 20px 17px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-variant: small-caps;
  text-transform: uppercase;
  font-weight: 600;
  color: #658ac8;
  font-size: 16px;
  line-height: 22px;

  &:hover ${Arrow} {
    transform: translate(5px, 0);
  }

  @media ${media.tablet} {
    width: 199px;
    padding: 14px 20px;
    background: ${({ theme }) => theme.colors.watermelon};
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    text-transform: none;
    font-variant: normal;

    &:hover {
      background: ${({ theme }) => theme.colors.salmon};
    }
  }
`;

type Props = {
  caseStudy: ICaseStudy;
};

const CaseStudiesItem: React.FC<Props> = (props) => {
  const { caseStudy } = props;

  const {
    slug,
    heading,
    listImage,
    listImageDesktop,
    position,
    techs,
    leadQuote,
    companyName,
  } = caseStudy;

  return (
    <Wrapper>
      <Card>
        <Content>
          <Heading>
            <Link to={`/success-story/${slug}/`}>
              <ImageWrapper>
                <Image>
                  {listImage && (
                    <StrapiImage image={[listImage, listImageDesktop]} />
                  )}
                </Image>
              </ImageWrapper>
            </Link>
            <Titles>
              <Title>
                <Typo35 fontWeight="500" color="#000">
                  {heading}
                </Typo35>
              </Title>
              <Link to={`/success-story/${slug}/`}>
                <Position>
                  <Typo24 fontWeight="500" color="#658AC8">
                    {`${position}${
                      !companyName ? "" : ` hired by ${companyName}`
                    }`}
                  </Typo24>
                  {techs.length > 0 && (
                    <TechsDesktop>
                      {techs.map((item) => (
                        <TechItemCustom
                          key={item.uid}
                          color={item.color}
                        >
                          {item.name}
                        </TechItemCustom>
                      ))}
                    </TechsDesktop>
                  )}
                </Position>
              </Link>
            </Titles>
          </Heading>
          {techs.length > 0 && (
            <Techs>
              {techs.map((item) => (
                <TechItemBadge techItem={item} key={item.uid} />
              ))}
            </Techs>
          )}
          {leadQuote && (
            <Quote>
              <Typo29 color="#1B1B1B">{leadQuote}</Typo29>
            </Quote>
          )}
        </Content>
        <ReadMoreWrapper>
          <ReadMoreButtonWrapper>
            <Link to={`/success-story/${slug}/`}>
              <ReadMore>
                <span>Read more</span>
                <Arrow>
                  <svg
                    width="20"
                    height="10"
                    viewBox="0 0 20 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.4697 1.31433C13.1768 1.02143 13.1768 0.54656 13.4697 0.253667C13.7626 -0.0392267 14.2374 -0.0392267 14.5303 0.253667L13.4697 1.31433ZM18 4.784L18.5303 4.25367L19.0607 4.784L18.5303 5.31433L18 4.784ZM14.5303 9.31433C14.2374 9.60722 13.7626 9.60722 13.4697 9.31433C13.1768 9.02143 13.1768 8.54656 13.4697 8.25367L14.5303 9.31433ZM14.5303 0.253667L18.5303 4.25367L17.4697 5.31433L13.4697 1.31433L14.5303 0.253667ZM18.5303 5.31433L14.5303 9.31433L13.4697 8.25367L17.4697 4.25367L18.5303 5.31433Z"
                      fill="#658AC8"
                    />
                    <path
                      d="M1.0006 4.784H17"
                      stroke="#658AC8"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                </Arrow>
              </ReadMore>
            </Link>
          </ReadMoreButtonWrapper>
        </ReadMoreWrapper>
      </Card>
    </Wrapper>
  );
};

export default CaseStudiesItem;
