import React from "react";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";
import ITechItem from "../../interfaces/ITechItem";
import { Typo15, Typo36 } from "../Typography";

interface ITechWrapper {
  color?: string;
}

export const TechItemStyled = styled.div<ITechWrapper>`
  display: flex;
  margin-top: 5px;
  align-items: center;
  height: 25px;
  padding: 4px 8px;
  margin-right: 8px;
  border-radius: 29px;
  overflow: hidden;
  flex-shrink: 0;

  ${({ color }) => (color ? `background: ${color};` : "background: #dedede;")}

  @media ${media.tablet} {
    padding-top: 2px;
    padding-bottom: 2px;
  }
`;

type Props = {
  techItem: ITechItem;
  small?: boolean;
};

const TechItemBadge: React.FC<Props> = (props) => {
  const { techItem, small } = props;

  const { color, name } = techItem;

  return (
    <TechItemStyled color={color}>
      {small ? (
        <Typo36 color={color ? "#0b264c" : `#0b254c`}>{name}</Typo36>
      ) : (
        <Typo15 color={color ? "#0b264c" : `#0b254c`}>{name}</Typo15>
      )}
    </TechItemStyled>
  );
};

export default TechItemBadge;
