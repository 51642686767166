import React from "react";
import getCaseStudySchema from "../../common/caseStudySchema";
import ICaseStudy from "../../interfaces/ICaseStudy";
import ISeo from "../../interfaces/ISeo";

import SEO from "../Seo";

type Props = {
  caseStudies: ICaseStudy[];
  seo: ISeo;
};

const CaseStudiesSEO: React.FC<Props> = (props) => {
  const { caseStudies, seo } = props;

  const { title, description, image } = seo;

  const schema = caseStudies.map((caseStudy) => getCaseStudySchema(caseStudy));

  const seoImagePath =
    image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src ||
    null;

  const imagePath = seoImagePath || null;

  const imageUrl = imagePath
    ? `https://${process.env.GATSBY_PUBLIC_DOMAIN}${imagePath}`
    : "";

  return (
    <SEO
      title={title || ""}
      description={description || ""}
      image={imageUrl}
      url={`/success-stories/`}
      schemaMarkup={schema}
    />
  );
};

export default CaseStudiesSEO;
