import React from "react";
import styled from "styled-components";
import Ribbon from "../../assets/svg/Ribbon";
import { Typo32, Typo35 } from "../Typography";
import BackButton from "../BackButton";
import { Typography } from "../HTMLContent/style";
import { media } from "../../common/MediaQueries";

const RiboonWrapper = styled.div`
  position: relative;
  display: flex;
  aling-items: center;
  justify-content: center;
  padding: 21px 48px 22px 48px;

  @media ${media.desktop} {
    padding-left: 125px;
    padding-right: 125px;
    max-width: 1554px;
    margin: 0 auto;
  }
`;

const RibbonContainer = styled.div`
  position: relative;
  padding: 10px 40px;
  background: linear-gradient(
    91.45deg,
    rgba(236, 94, 115, 0.25) 0%,
    rgba(248, 125, 248, 0.25) 43.02%,
    rgba(116, 143, 248, 0.25) 75.49%,
    rgba(99, 181, 245, 0.25) 100%
  );

  @media ${media.tablet} {
    padding: 12px 78px 14px 78px;
  }
`;

const Text = styled.div`
  letter-spacing: -0.01em;
`;

const Overlay = styled.div`
  position: absolute;
  right: -1px;
  top: 0;
  height: 100%;
`;

const OverlayFlipped = styled(Overlay)`
  right: auto;
  left: -1px;
  transform: rotate(180deg);
`;

const SubheadingsWrapper = styled.div`
  padding: 24px 27px;
  background: #f6f9ff;

  @media ${media.tablet} {
    padding: 41px 125px 39px 125px;
  }
`;

const Subheading = styled.div`
  letter-spacing: -0.01em;

  @media ${media.tablet} {
    letter-spacing: -0.0028em;
  }
`;

const SubheadingText = styled.div`
  margin-top: 20px;
  letter-spacing: -0.01em;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #0b264c;
  opacity: 0.67;

  @media ${media.tablet} {
    margin-top: 17px;
    font-size: 20px;
    line-height: 32px;
    opacity: 0.7;
    color: #1b1b1b;
    letter-spacing: -0.002em;
  }
`;

const BackButtonWrapper = styled.div`
  position: absolute;
  top: 21px;
  left: 27px;

  @media ${media.desktop} {
    left: 125px;
    top: 21px;
  }
`;

type Props = {
  heading: string;
  subheading: string;
  text: string;
};

const CaseStudiesHero: React.FC<Props> = (props) => {
  const { heading, subheading, text } = props;

  return (
    <>
      <RiboonWrapper>
        <RibbonContainer>
          <OverlayFlipped>
            <Ribbon />
          </OverlayFlipped>
          <Text>
            <Typo32 
              as="h1"
              fontWeight="700"
              textAlign="center"
              color="#1B1B1B"
            >
              {heading || "Success Stories"}
            </Typo32>
          </Text>
          <Overlay>
            <Ribbon />
          </Overlay>
        </RibbonContainer>
        <BackButtonWrapper>
          <BackButton to={"/jobs/"} />
        </BackButtonWrapper>
      </RiboonWrapper>
      {(subheading || text) && (
        <SubheadingsWrapper>
          {subheading && (
            <Subheading>
              <Typo35 fontWeight="500" textAlign="center" color="#000000">
                {subheading}
              </Typo35>
            </Subheading>
          )}
          {text && (
            <SubheadingText>
              <Typography dangerouslySetInnerHTML={{ __html: text }} />
            </SubheadingText>
          )}
        </SubheadingsWrapper>
      )}
    </>
  );
};

export default CaseStudiesHero;
