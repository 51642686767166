import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";
import ICaseStudy from "../../interfaces/ICaseStudy";
import IStrapiImage from "../../interfaces/IStrapiImage";
import CaseStudiesItem from "../CaseStudiesItem";
import CTAWithImage from "../CTAWithImage";
import { Typo32 } from "../Typography";

const Empty = styled.div`
  min-height: 80vh;
  padding: 27px;

  @media ${media.desktop} {
    padding: 125px;
    max-width: 1554px;
    margin: 0 auto;
  }
`;

const Wrapper = styled.div`
  padding: 34px 27px 43px 27px;

  @media ${media.desktop} {
    padding: 18px 125px 65px 125px;
    max-width: 1554px;
    margin: 0 auto;
  }
`;

const NegativeMargin = styled.div`
  @media ${media.tablet} {
    margin: 0 -20px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const CtaWrapper = styled.div`
  padding-top: 59px;
  padding-bottom: 10px;

  @media ${media.tablet} {
    width: 100%;
    padding-top: 75px;
    padding-bottom: 75px;
  }
`;

type Props = {
  caseStudies: ICaseStudy[];
  ctaImage: IStrapiImage;
  ctaImageDesktop: IStrapiImage;
  ctaText: string;
};

const CaseStudiesList: React.FC<Props> = (props) => {
  const { caseStudies, ctaText, ctaImage, ctaImageDesktop } = props;

  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    setShowAll(true);
  }, []);

  if (caseStudies.length === 0) {
    return (
      <Empty>
        <Typo32 textAlign="center">
          Looks like we don't have any case studies yet.
        </Typo32>
      </Empty>
    );
  }

  const currentCaseStudies = showAll ? caseStudies : caseStudies.slice(0, 10);

  return (
    <Wrapper>
      <NegativeMargin>
        {currentCaseStudies.map((item, index) => {
          const { slug } = item;

          return (
            <React.Fragment key={slug}>
              {index !== 0 && index % 6 === 0 && (
                <CtaWrapper>
                  <CTAWithImage
                    text={ctaText}
                    image={ctaImage}
                    imageDesktop={ctaImageDesktop}
                    width={321}
                    height={71}
                    desktopWidth={700}
                    desktopHeight={70}
                  />
                </CtaWrapper>
              )}
              <CaseStudiesItem caseStudy={item} />
            </React.Fragment>
          );
        })}
      </NegativeMargin>
    </Wrapper>
  );
};

export default CaseStudiesList;
