import { graphql } from "gatsby";
import React from "react";
import normalizeCaseStudiesPage from "../common/normalizeCaseStudiesPage";
import CaseStudiesHero from "../components/CaseStudiesHero";
import CaseStudiesList from "../components/CaseStudiesList";
import CaseStudiesSEO from "../components/CaseStudiesSEO";
import Layout from "../components/Layout";
import "../fragments/CaseStudy";
import "../fragments/Company";
import ICaseStudy from "../interfaces/ICaseStudy";
import ISeo from "../interfaces/ISeo";
import IStrapiImage from "../interfaces/IStrapiImage";

type Props = {
  data: any;
};

const CaseStudies: React.FC<Props> = (props) => {
  const data: any = normalizeCaseStudiesPage(props.data);

  const {
    caseStudies,
    heading,
    subheading,
    text,
    ctaImage,
    ctaImageDesktop,
    ctaText,
    seo,
  }: {
    caseStudies: ICaseStudy[];
    heading: string;
    subheading: string;
    text: string;
    ctaImage: IStrapiImage;
    ctaImageDesktop: IStrapiImage;
    ctaText: string;
    seo: ISeo;
  } = data;

  return (
    <Layout
      headerLocation="success"
      footerBorderOnMobile={true}
    >
      <CaseStudiesSEO
        caseStudies={caseStudies}
        seo={seo} 
      />
      <CaseStudiesHero heading={heading} subheading={subheading} text={text} />
      <CaseStudiesList
        ctaText={ctaText}
        ctaImage={ctaImage}
        ctaImageDesktop={ctaImageDesktop}
        caseStudies={caseStudies}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    strapiCaseStudiesPage {
      heading
      subheading
      text
      ctaText
      ctaImage {
        alternativeText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 321
              height: 71
              quality: 100
              placeholder: NONE
              transformOptions: { fit: COVER, cropFocus: CENTER }
            )
          }
        }
      }
      ctaImageDesktop {
        alternativeText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 700
              height: 70
              quality: 100
              placeholder: NONE
              transformOptions: { fit: COVER, cropFocus: CENTER }
            )
          }
        }
      }
      caseStudies {
        slug
      }
      seo {
        title
        description
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1200
                height: 640
                quality: 100
                placeholder: NONE
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
    allStrapiCasestudies {
      edges {
        node {
          ...CaseStudy
        }
      }
    }
    allStrapiCompanies {
      edges {
        node {
          ...Company
        }
      }
    }
  }
`;

export default CaseStudies;
